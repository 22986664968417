<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" @change="setData">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>

    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <field-attributes :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="10">
        <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="mb-10">
          <label>Currency Type</label>
        </div>
        <el-radio
          v-model="field.validations.selected_currency_type"
          :label="false"
          @change="handleRadioChange"
        >
          From Template</el-radio
        >
        <el-radio
          v-model="field.validations.selected_currency_type"
          :label="true"
          @change="handleRadioChange"
        >
          Standard
        </el-radio>
        <div
          v-if="field.validations.selected_currency_type === false"
          ref="templateSelectContainer"
        >
          <el-select
            v-model="field.validations.selected_currency_field_key"
            filterable
            clearable
            @change="currencyVariable"
            class="select-input mb-10"
          >
            <el-option
              v-for="(op, i) in getCurrencyTypeFields()"
              :key="i + 'op' + op"
              :label="op.label"
              :value="op.key"
            >
              <span style="float: left">{{ op.label }}</span>
            </el-option>
          </el-select>
        </div>
        <div v-else ref="standardSelectContainer">
          <el-select
            id="currencySelect"
            v-model="field.validations.currency"
            filterable
            @change="currencyVariable"
            class="select-input mb-1"
          >
            <el-option
              v-for="item in CurrencyTypes"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
              <span style="float: left">{{ item.value }}</span>
              <span
                style="
                  float: right;
                  color: var(--el-text-color-secondary);
                  font-size: 13px;
                "
                >{{ item.name }}</span
              >
            </el-option>
          </el-select>
        </div>
      </el-row>
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  filterable
                  :label="globalVariable.label"
                  :value="globalVariable._id"
                >
                  <span style="float: left">{{ globalVariable.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    globalVariable.input_type
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
        <is-field-required :field="field" />
    </el-form>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
//import { mapGetters } from "vuex";
import { fetchGlobalVariables } from "@/repo/globalVariables"
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    FieldAttributes: () => import("./FieldAttributes"),
  },
  props: ["field", "fieldsData"],
  computed: {
    //...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  data() {
    return {
      validations: [],
      currencyTypeFields: [],
      CurrencyTypes: [
        { value: "USD", name: "$" },
        { value: "CAD", name: "$" },
        { value: "AUD", name: "$" },
        { value: "INR", name: "₹" },
        { value: "EUR", name: "€" },
        { value: "MXN", name: "$" },
        { value: "ZAR", name: "R" },
        { value: "MYR", name: "RM" },
        { value: "GBP", name: "£" },
      ],
      iconPickerdialogVisible: false,
      getAllGlobalVariables: null,
    };
  },
  mounted() {
    this.fetchGlobalVaribales();
    if (this.field.data_table_key) {
      let dataTable = this.fieldsData.find(e => e.key == this.field.data_table_key);
      this.currencyTypeFields =  (dataTable?.data_table_columns || []).filter(
        (field) => field.inputType === "CURRENCY_TYPE"
      );
    } else {
      this.currencyTypeFields = this.fieldsData.filter(
        (field) => field.inputType === "CURRENCY_TYPE"
      );
    }
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
    handleRadioChange() {
      this.$nextTick(() => {
        const templateSelect =
          this.$refs.templateSelectContainer?.querySelector(".el-select input");
        const standardSelect =
          this.$refs.standardSelectContainer?.querySelector(".el-select input");
        if (this.field.validations.selected_currency_type === true) {
          this.field.validations.selected_currency_field_key = null;
          if (templateSelect) templateSelect.blur();
          if (standardSelect) standardSelect.focus();
        } else {
          this.field.validations.currency = null;
          if (this.currencyTypeFields.length === 0) {
            this.$message.info(
              "Place the Currency Type Fields in the template"
            );
          }
          if (standardSelect) standardSelect.blur();
          if (templateSelect) templateSelect.focus();
        }
      });
    },
    getCurrencyTypeFields() {
      return this.currencyTypeFields;
    },
    setData() {
      if (!this.field.is_global_variable) {
        this.field.options = [];
        this.field.global_variable_id = null;
      }
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_types: ["CURRENCY"],
      };
      this.getAllGlobalVariables = await fetchGlobalVariables(params);
    },

    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
      this.field.validations.currency = globalVariable.currency;
    },
    currencyVariable() {
      if (this.field.validations.currency == "INR") {
        this.field.validations.locale = "en-IN";
      } else if (this.field.validations.currency == "USD") {
        this.field.validations.locale = "en-US";
      } else if (this.field.validations.currency == "CAD") {
        this.field.validations.locale = "en-CA";
      }  else if (this.field.validations.currency == "MXN") {
        this.field.validations.locale = "es-MX";
      }  else if (this.field.validations.currency == "ZAR") {
        this.field.validations.locale = "en-ZA";
      }  else if (this.field.validations.currency == "MYR") {
        this.field.validations.locale = "ms-MY";
      }  else if (this.field.validations.currency == "GBP") {
        this.field.validations.locale = "en-GB";
      } else {
        this.field.validations.locale = "en-IE";
      }
    },
  },
};
</script>

<style lang="scss">
.currency:before {
  content: "*";
  color: red;
  position: absolute;
  margin-left: 107px;
  margin-top: 4px;
}
</style>
<style lang="scss" scoped>
.form-items-row {
  display: flex;
  justify-content: space-between;
}
.form-item-col {
  display: flex;
  flex-direction: column;
}
.select-input {
  width: 48%;
}
</style>
